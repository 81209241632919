import React, { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosInstance'; // Import the Axios instance
import Swal from 'sweetalert2';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from '../../dashboard/context/AuthContext';


const Createnft = () => { 
    const { logout,defUrllink, fetchUserData } = useAuth();
    const [userData , setUserData] = useState();

    const [formData, setFormData] = useState({
        bal: null,
        wallet: null,

    });
    const [uploadError, setUploadError] = useState(null); // State to store upload error
    const [imageUrl, setImageUrl] = useState(null);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: name === 'image' ? files[0] : value,
        }));

        if (name === 'image' && files.length > 0) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setImageUrl(reader.result);
            };
            reader.readAsDataURL(files[0]);
          }
    };


    const resetForm = () => {
        setFormData({
          image: null,
          name: '',
          category: '',
          price: '',
          description: ''
        });
        // If you're using a <form> element, reset it directly to also clear file input
        document.getElementById("uploadForm").reset();
      };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const { image, name, category, price, description } = formData;

        // Validate form fields
        if (!image || !name || !category || !price || !description) {
            
            toast.warning('Please fill in all fields.', {
                position: "top-right"
            });
            return false;
        }

        // Send NFT data to backend
        const formDataToSend = new FormData();
        formDataToSend.append('image', image);
        formDataToSend.append('name', name);
        formDataToSend.append('category', category);
        formDataToSend.append('price', price);
        formDataToSend.append('description', description);


        try {
            // Make API call to upload NFT using formDataToSend
            const response = await axiosInstance.post('main.php?xAction=uploadnft&', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data.status === 'success') {
               
                toast.success(response.data.id, {
                    position: "top-center"
                  });
                   Swal.close();
                resetForm();
                return true;
            } else {
              
                toast.error(response.data.id, {
                    position: "top-center"
                  });
                  return false; 
            }
            console.log('NFT upload response:', response.data);
            // Handle success
            // Redirect user or show success message
        } catch (error) {
            console.error('NFT upload failed:', error);
            setUploadError('NFT upload failed. Please try again.');
            return false;
        }
    };

    const handelconsent = () => {
        const { image, name, category, price, description } = formData;

        // Validate form fields
        if (!image || !name || !category || !price || !description) {
            
            toast.warning('Please fill in all fields.', {
                position: "top-right"
            });
            return;
        }
        
        if(formData.bal >=0.1) {
            Swal.fire({
                title: '',
                html: `<section class="flex flex-col items-center gap-12 py-8 px-6">
                            <div class="flex flex-col items-center gap-4 w-full">
                            <p class="font-bold text-xl text-center">NFT Minting Confirmation</p>
                            <div class="relative w-full aspect-square lg:aspect-[5/4]">
                            <img alt="`+formData.name+`" decoding="async" data-nimg="fill" class="object-cover rounded-md md:rounded-lg" style="position: absolute; height: 100%; width: 100%; inset: 0px; color: transparent;" src="`+imageUrl+`">
                            </div>
                            <div class="flex flex-col gap-3 w-full">
                            <p class="flex items-start justify-between gap-16"><span class="text-neutral-700 text-sm flex-none">NFT Name</span><span class="font-bold text-right">`+formData.name+`</span></p>
                            <p class="flex items-center justify-between gap-1"><span class="text-neutral-700 text-sm flex-none">NFT Price</span><span class="font-bold">`+formData.price+` wETH</span></p>
                            <p class="flex items-center justify-between gap-1"><span class="text-neutral-700 text-sm flex-none">NFT Category</span><span class="font-bold capitalize">`+formData.category+`</span></p>
                            </div>
                            </div>
                            <div class="flex flex-col gap-2 w-full">
                            <div class="flex items-center gap-1">
                            <input type="checkbox" id="agreeToTerms" name="agreeToTerms" />
                            <p class="flex items-center text-sm">A 15% commission in ETH applies to successful sales.</p>
                            </div>
                            <button id="continueButton" class="btn btn-primary btn-lg" disabled>Continue</button>
                            </div>
                            </section>`,
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false,
                position: 'centre',
                buttons: [],
                customClass: {
                    closeButton: 'swal-close-button',
                    container: 'fullscreen-swal'
                },
                didOpen: () => {
                    const checkbox = document.getElementById('agreeToTerms');
                    const continueButton = document.getElementById('continueButton');
                    const externalForm = document.getElementById('nftform'); // Make sure to replace 'externalFormId' with your actual form's ID
            
                    const handleChange = () => {
                        continueButton.disabled = !checkbox.checked;
                    };
            
                    checkbox.addEventListener('change', handleChange);
            
                    continueButton.addEventListener('click', (event) => {
                        event.preventDefault();
            
                        // Disable button before handling submission
                        continueButton.disabled = true;
            
                        // Handle form submission
                        event.preventDefault();

                            // Disable button before handling submission
                            continueButton.disabled = true;

                            // Handle form submission
                            const submitSuccess = handleSubmit(event);

                            if (submitSuccess) {
                                // Re-enable button if submission successful
                                continueButton.disabled = false;
                                // Close the SweetAlert dialog
                               
                            } else {
                                // Re-enable button if submission failed
                                continueButton.disabled = false;
                            }
                    });
            
                    // Call handleChange initially to set the button state correctly
                    handleChange();
                },
                willClose: () => {
                    // Clean up: Remove the event listener to prevent memory leaks
                    const checkbox = document.getElementById('agreeToTerms');
                    checkbox.removeEventListener('change', handleChange);
                }
            });
            
        }else{
            Swal.fire({
                title: '',
                html: `<section class="flex flex-col items-center gap-8 py-4 px-6">
                        <div class="flex flex-col gap-6">
                          <div class="flex flex-col gap-3">
                            <h2 class="text-2xl font-bold text-red-600">Insufficient Balance</h2>
                            <p>You need at least <span class="font-bold">0.1 ETH</span> in your Ethereum wallet to create an NFT. Please kindly make a deposit.</p>
                          </div>
                          <div>
                            <p class="mb-3">Copy your address to make a deposit or click the deposit button.</p>
                            <div class="flex items-center justify-between border border-mid-100 p-2 rounded-lg">
                              <p id="wallet-address" class="font-bold xs:hidden truncate-container">0x${formData.wallet}</p>
                              <p id="wallet-address-md" class="font-bold hidden xs:block md:hidden truncate-container">0x${formData.wallet}</p>
                              <p id="wallet-address-lg" class="hidden font-bold md:block">0x${formData.wallet}</p>
                              <button id="copy-button" class="inline-flex items-center justify-center ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-25 dark:ring-offset-neutral-950 dark:focus-visible:ring-neutral-300 text-neutral-50 dark:bg-neutral-50 dark:text-neutral-900 dark:hover:bg-neutral-50/90 px-6 py-2 h-10 rounded-md bg-brand font-bold hover:bg-brand/80">Copy</button>
                            </div>
                            <span class="text-xs">Available balance: <span class="font-bold text-sm">${formData.bal} ETH</span></span>
                          </div>
                        </div>
                        <div class="w-full">
                          <a href="/dashboard/deposit"><button class="inline-flex items-center justify-center rounded-lg ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-25 dark:ring-offset-neutral-950 dark:focus-visible:ring-neutral-300 text-neutral-50 dark:bg-neutral-50 dark:text-neutral-900 dark:hover:bg-neutral-50/90 px-6 py-2 h-12 w-full bg-brand font-bold hover:bg-brand/80">Deposit</button></a>
                        </div>
                      </section>`,
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false,
                position: 'center',
                buttons: [],
                customClass: {
                  closeButton: 'swal-close-button',
                  container: 'fullscreen-swal'
                },
                didOpen: () => {
                    document.getElementById('copy-button').addEventListener('click', function() {
                        //var walletAddress = document.getElementById('wallet-address').innerText;
                        copyToClipboard('0x'+formData.wallet);
                      });
                },
              });
        }
        
      };
    
      async function fetchData() {
        // fetch data    
        try {
            const resp = await fetchUserData();
            if (resp) {
                const userData = resp[0]; // Assuming fetchUserData returns an array with a single user object
                setFormData({
                    bal: userData.eth_balance,
                    wallet: userData.eth_address,
                });
            } else {
                // Handle case where response is empty or undefined
                console.error("Failed to fetch user data");
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            // Handle error, show toast message or retry fetch
        }
    }
    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            
            toast.success("Text copied to clipboard", {
                position: "top-right"
            });
            // Revert the icon back to its original state after 3 seconds
           
        } catch (err) {
            console.error('Failed to copy text', err);
        }
    }
    
    useEffect(() => {
        fetchData();
      
      }, []);

    return (
        <div className="main_content_iner ">
            <div className="container-fluid plr_30 body_white_bg pt_10">
                <div className="row justify-content-center">
                    <div className="d-flex justify-content-between m-2">
                        <div className="d-flex align-items-center gap-3 fs-2 fw-bold">
                            <div className="d-flex mb-1">
                                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" className="cursor-pointer" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m15 18-6-6 6-6"></path>
                                </svg>
                            </div>

                            <h2 className="text-charcoal fw-bold">Create NFT</h2>
                        </div>

                    </div>
                    {/* maincode */}

                    <section>
                        {/* form for creating nft */}
                        <div class="text-mid-100 max-w-3xl"><p class="text-dark">Image Model</p><p class="text-secondary">File types supported: JPG, JPEG, PNG, GIF, WEBM. Max size: 15 MB</p></div>
                        <form class="flex flex-col gap-4" onSubmit={handleSubmit} id='nftform'>

                            <section>
                                <div className="w-full flex justify-center h-full">
                                    <div className="upload-box w-full max-w-none" style={{ height: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center', }}>
                                        <input type="file" id="nftImage" accept="image/*" name="image" onChange={handleChange} required style={{ display: 'none' }} />
                                        <label htmlFor="nftImage" className="w-100 h-100 cursor-pointer border border-secondary border-dashed rounded p-3 w-full flex flex-col items-center justify-center">
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" className="fs-3 text-mid-200" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style={{ margin: 'auto' }}>
                                                    <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7"></path>
                                                    <line x1="16" x2="22" y1="5" y2="5"></line>
                                                    <line x1="19" x2="19" y1="2" y2="8"></line>
                                                    <circle cx="9" cy="9" r="2"></circle>
                                                    <path d="m21 15-3.086-3.086a2 2 0 0 0-2.828 0L6 21"></path>
                                                </svg>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </section>
                            <div class="form-group">
                                <label for="nftName" class="col-form-label">Name</label>
                                <input type="text" class="form-control" id="nftName" name="name" value={formData.name} onChange={handleChange} required placeholder="NFT name" />
                            </div>

                            <div class="row mt-1">
                                <div class="col-md-6 mt-1">
                                    <div class="form-group">
                                        <label for=":r38:-form-item" class="form-label">Category</label>

                                        <select class="form-select"
                                            name="category"
                                            value={formData.category}
                                            onChange={handleChange}
                                            required >

                                            <option value=""></option>
                                            <option value="art">Art</option>
                                            <option value="gaming">Gaming</option>
                                            <option value="memberships">Memberships</option>
                                            <option value="pfps">PFPs</option>
                                            <option value="photography">Photography</option>
                                            <option value="others">Others</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-6 mt-1">
                                    <div class="form-group ">
                                        <label for=":r3a:-form-item" class="form-label">Price In wETH</label>
                                        <input class="form-control" step="0.00001" placeholder="Enter the price in wETH" type="number"
                                            name="price"
                                            value={formData.price}
                                            onChange={handleChange}
                                            required aria-invalid="false" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mt-2">
                                <label for="nftDescription" class="form-label">Description</label>
                                <textarea class="form-control" name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    required rows="3" placeholder="Provide a detailed description of your NFT." ></textarea>
                            </div>

                            <div>Minting fee: 0.1 ETH</div>

                            <section className="d-flex justify-content-end">
                                <a className="btn btn-lg btn-primary" style={{ margin: '10px' }} onClick={handelconsent}>
                                    Create
                                </a>
                            </section>






                        </form>
                    </section>

                </div>
            </div>
        </div>
    );

};

export default Createnft;
