import React from 'react';
import { useAuth } from '../context/AuthContext';
import ShowNft from '../components/shownft';
import { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchEthToUsdRate } from '../api/ethrate';
import DisplayTrnx from './displaytrnx';
import DisplaySales from './displaysales';

const Accountsummary = () => {
    const { userData,fetchUserData } = useAuth();
    
    const [data, setData] = useState(null);
    const [userdata, setUserData] = useState([]);
    const [Balance, setBalanace] = useState(null);
    const [weth, setWeth] = useState(null);
    const [eth, seteth] = useState(null);
    const [ethPercent, setEthPercent] = useState(null);
    const [wethPercent, setWethPercent] = useState(null);

    async function fetchData() {
        // fetch data    
        const resp = await fetchUserData()
        const UserData = {
            userid: resp.map(user => user.uniqueid),
            category: "",
            offset: 0,
            limit: 2,
            mine:true,    
        }; 
        setUserData(resp);
        setData(UserData);
        const rate = await fetchEthToUsdRate();
        let called_balance = resp.reduce((acc, user) => {
            return acc + parseFloat(user.eth_balance) + parseFloat(user.weth_balance);
        }, 0);
        setBalanace((rate*called_balance).toLocaleString(undefined, {
            style: 'currency',
            currency: 'USD',
            currencyDisplay: 'symbol'
        }).replace('US$', '$'));
        console.log(called_balance);

        let Weth_balance = resp.map(user => user.weth_balance);
        setWeth((rate*Weth_balance).toLocaleString(undefined, {
            style: 'currency',
            currency: 'USD',
            currencyDisplay: 'symbol'
        }).replace('US$', '$'));
        let eth_balance = resp.map(user => user.eth_balance);
        seteth((rate*eth_balance).toLocaleString(undefined, {
            style: 'currency',
            currency: 'USD',
            currencyDisplay: 'symbol'
        }).replace('US$', '$'));
        console.log(eth_balance);
        
       // Parse called_balance and Weth_balance as integers
    const intCalledBalance = parseFloat(eth_balance);
    const intWethBalance = parseFloat(Weth_balance);

    // Calculate the total by adding the integer balances
    const total = (intCalledBalance + intWethBalance)*1;

    // Calculate the percentages
    const ethPercentage = total !== 0 ? (intCalledBalance * 100) / total : 0;
    const wethPercentage = total !== 0 ? (intWethBalance * 100) / total : 0;

    // Set the integer percentages in the state
    setEthPercent(parseFloat(ethPercentage.toFixed(2)));
    setWethPercent(parseFloat(wethPercentage.toFixed(2)));

      }
     
   
    useEffect(() => {
        fetchData();
      
      }, []);
    

    return (

        <div class="main_content_iner ">
            <div class="container-fluid plr_30 body_white_bg pt_10">
                <div class="row justify-content-center">
                    <div class="text-2xl md:text-3xl"><h3 class="text-charcoal font-bold"></h3></div>

                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-xl-8">
                            <div class="single_element">
                                <div class="quick_activity">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="">
                                                <div class="single_quick_activity" style={{ backgroundColor: 'rgb(245, 245, 245)' }}>
                                                    <div className='row'>
                                                        <div class="col-lg-4 text-charcoal font-bold">
                                                            <h4 style={{ color: 'black', }}>Account Balance</h4>
                                                            <h3 style={{ color: 'black', }}> <span class="counter">{Balance !== null ? Balance.toLocaleString(undefined, {style: 'currency',currency: 'USD',currencyDisplay: 'symbol'}).replace('US$', '$') : (<div class="spinner-border text-secondary" role="status"><span class="visually-hidden">Loading...</span></div>)}</span><span style={{ color: 'black', fontSize: '50%', fontWeight: 300 }}>USDT</span></h3>
                                                        </div>
                                                        <div className="col-lg-8 mt-20 d-flex justify-content-center align-items-end" style={{ zIndex: 1 }}>
                                                            <Link type="button" to="/dashboard/deposit" className="btn btn-primary flex-fill m-1 border">Deposit</Link>
                                                            <Link type="button" to="/dashboard/create-nft" className="btn btn-outline-dark flex-fill m-1 border">Mint</Link>
                                                            <Link type="button" to="/dashboard/withdraw" className="btn btn-outline-dark flex-fill m-1 border">Withdraw</Link>
                                                        </div>

                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="single_element mt-3">
                                <div class="quick_activity">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="">
                                                <div class="single_quick_activity" style={{ backgroundColor: 'rgb(245, 245, 245)' }}>
                                                    <section className='flex flex-col justify-between gap-4 p-6 bg-light-100 rounded-xl md:p-8'>
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <p className="text-sm" style={{ color: 'black' }}>Coin</p>
                                                            </div>
                                                            <div className="col-4">
                                                                <p className="text-sm" style={{ color: 'black' }}>Ratio</p>
                                                            </div>
                                                            <div className="col-4 text-right">
                                                                <p className="text-sm" style={{ color: 'black' }}>Amount</p>
                                                            </div>
                                                        </div>


                                                        <div class="row">
                                                            <div class="col-4">
                                                                <div class="d-flex align-items-center gap-2">
                                                                    <img alt="coin" class="w-[0.65rem]" style={{ width: '30px' }} src="img/eth.webp" />
                                                                    <div class="d-flex flex-column font-semibold">
                                                                        <span class="text-sm">ETH</span>
                                                                        <span class="text-[0.65rem]">ERC20</span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="col-4">
                                                                <div class="d-flex flex-column gap-1 font-semibold">
                                                                    <span class="text-sm">{ethPercent}%</span>
                                                                    <span>
                                                                        <div class="progress relative h-0.5 overflow-hidden rounded-full bg-light-200 dark:bg-neutral-800 w-[40%] w-50" style={{height: '0.3rem'}}>
                                                                            <div role="progressbar" aria-valuenow={ethPercent} aria-valuemin="0" aria-valuemax="100" className="progress-bar bg-primary" style={{ transform: 'translateX(-1.01%)', width: ethPercent+'%' }}></div>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 text-right">
                                                                <div class="col-4 text-center">
                                                                    <div class="d-flex flex-column font-semibold">
                                                                        <span class="text-sm font-[900]">{userdata.map(user => user.eth_balance)}</span>
                                                                    </div>
                                                                    <span class="d-flex align-items-center justify-content-start gap-1 text-[0.5rem] font-bold">
                                                                        <span class="text-[0.75rem]">≈</span>
                                                                        <span>{eth !== null ? eth : (<div class="spinner-border text-secondary" role="status"><span class="visually-hidden">Loading...</span></div>)}</span>
                                                                    </span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-4">
                                                                <div class="d-flex align-items-center gap-2">
                                                                    <img alt="coin" class="w-[0.65rem]" style={{ width: '30px' }} src="img/weth.webp" />
                                                                    <div class="d-flex flex-column font-semibold">
                                                                        <span class="text-sm">WETH</span>
                                                                        <span class="text-[0.65rem]">ERC20</span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="col-4">
                                                                <div class="d-flex flex-column gap-1 font-semibold">
                                                                    <span class="text-sm">{wethPercent}%</span>
                                                                    <span>
                                                                        <div class="progress relative h-0.5 overflow-hidden rounded-full bg-light-200 dark:bg-neutral-800 w-[40%] w-50" style={{height: '0.3rem'}}>
                                                                            <div role="progressbar" aria-valuenow={wethPercent} aria-valuemin="0" aria-valuemax="100" className="progress-bar bg-primary" style={{ transform: 'translateX(-1.01%)', width: wethPercent+'%' }}></div>
                                                                        </div>

                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 text-right">
                                                                <div class="col-4 text-center">
                                                                    <div class="d-flex flex-column font-semibold">
                                                                        <span class="text-sm font-[900]">{userdata.map(user => user.weth_balance)}</span>
                                                                    </div>
                                                                    <span class="d-flex align-items-center justify-content-start gap-1 text-[0.5rem] font-bold">
                                                                        <span class="text-[0.75rem]">≈</span>
                                                                        <span>{weth !== null ? weth : (<div class="spinner-border text-secondary" role="status"><span class="visually-hidden">Loading...</span></div>)}</span>
                                                                    </span>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </section>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="single_element mt-3">
                                <div class="quick_activity">
                                    <div class="col-lg-12 mt-4 d-flex justify-content-between">
                                        <h6 style={{ color: 'black', fontWeight: 300 }}>NFTs</h6>
                                        <Link to='mycollection' type="button" className="btn btn-secondary btn-sm ms-auto" style={{ backgroundColor: '#f5f5f5', color: 'black' }}>View all</Link>
                                    </div>

                                    <div class="row m mt-3">
                                        { data? <ShowNft userData={data} /> : <>loading....</> }
                                        
                                        
                                        

                                       

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-xl-4 mt-sm-1 mt-2 mt-lg-0 quick_activity" >
                            <div class='p-1 single_quick_activity' style={{ backgroundColor: 'rgb(245, 245, 245)', borderRadius: '10px' }}>



                                <div class=" d-flex justify-content-between align-items-end ">
                                    <h6 className='m-3' style={{ color: 'black', fontWeight: 300 }}>Recent Transactions</h6>
                                    <Link to='/dashboard/transaction-history' type="button" className="btn btn-secondary btn-sm m-3" style={{ backgroundColor: '#f5f5f5', color: 'black' }}>View all</Link>
                                </div>
                                <div className="flex flex-col gap-4">
                                <DisplayTrnx limit={5} page={'false'} type={'All'}/>


                                </div>

                            </div>

                            <div class='p-1 mt-3 single_quick_activity' style={{ backgroundColor: 'rgb(245, 245, 245)', borderRadius: '10px' }}>


                                <div class=" d-flex justify-content-between align-items-end ">
                                    <h6 className='m-3' style={{ color: 'black', fontWeight: 300 }}>Recent Sales</h6>
                                    <Link to='/dashboard/sales-history' type="button" className="btn btn-secondary btn-sm m-3" style={{ backgroundColor: '#f5f5f5', color: 'black' }}>View all</Link>
                                </div>
                                <div className="flex flex-col gap-4">
                                   
                                    <DisplaySales limit={5} page={'false'}/>



                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Accountsummary;
