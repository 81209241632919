import { useState, useEffect } from "react";
import { Tabs, Tab, TabList } from 'react-bootstrap';
import DisplaySales from './displaysales';

const SalesHistory = () => {
    const [activeTab, setActiveTab] = useState('All');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };



    return (
        <div className="main_content_iner">
            <div className="container-fluid plr_30 body_white_bg pt_10">
                <div className="row justify-content-center">
                    <div className="d-flex justify-content-between m-2">
                        <div className="d-flex align-items-center gap-3 fs-2 fw-bold">
                            <div className="d-flex mb-1">
                                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" className="cursor-pointer" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m15 18-6-6 6-6"></path>
                                </svg>
                            </div>

                            <h2 className="text-charcoal fw-bold">Sales History</h2>
                        </div>
                    </div>
                    <div className='col-12'>
                    <div className='grid lg:grid-cols-[2fr_1fr] gap-0 lg:gap-10'>
                            <div>
                                <div className="tabs rounded">
                                    <button
                                        className={`tab-button m-2 ${activeTab === 'All' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('All')}>
                                        All
                                    </button>
                                    <button
                                        className={`tab-button m-2 ${activeTab === 'Buy' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('Buy')}>
                                        Buy
                                    </button>
                                    <button
                                        className={`tab-button m-2 ${activeTab === 'Sell' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('Sell')}>
                                        Sell
                                    </button>
                                    
                                </div>

                                <div className="tab-content" style={{ display: activeTab === 'All' ? 'block' : 'none' }}>
                                    {/* <h2>Host Exhibition</h2> */}
                                    <div>
                                        <div class='single_quick_activity' style={{ backgroundColor: 'rgb(245, 245, 245)', borderRadius: '10px' }}>
                                            <div className="flex flex-col gap-4">
                                                <DisplaySales limit={10} page={'true'} type={'All'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-content" style={{ display: activeTab === 'Buy' ? 'block' : 'none' }}>
                                    {/* <h2>Ongoing Exhibitions</h2> */}
                                    <div>
                                        <div class='single_quick_activity' style={{ backgroundColor: 'rgb(245, 245, 245)', borderRadius: '10px' }}>
                                            <div className="flex flex-col gap-4">
                                                {/* <DisplaySales limit={10} page={'true'} type={'Buy'} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-content" style={{ display: activeTab === 'Sell' ? 'block' : 'none' }}>
                                    {/* <h2>Ongoing Exhibitions</h2> */}
                                    <div>
                                        <div class='single_quick_activity' style={{ backgroundColor: 'rgb(245, 245, 245)', borderRadius: '10px' }}>
                                            <div className="flex flex-col gap-4">
                                                <DisplaySales limit={10} page={'true'} type={'Sell'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>




                </div>
            </div>
        </div>
    );
};

export default SalesHistory;
