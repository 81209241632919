import React, { useState, useEffect, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Button from './Button';
import Container from './Container';
import { Link } from 'react-router-dom';
import { useAuth } from '../../dashboard/context/AuthContext';
import LazyLoad from 'react-lazyload';
import { fetchEthToUsdRate } from '../../dashboard/api/ethrate';
import SwiperCore, { Navigation, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/lazy';

SwiperCore.use([Navigation, Pagination]);

const commonBiggerScreen = {
  centeredSlides: false,
  centeredSlidesBounds: false,
  spaceBetween: 15,
  slidesOffsetBefore: 0,
  slidesOffsetAfter: 0,
};

function ProfileCollection({ uid, title }) {
  const { fetchNftData } = useAuth();
  const [selectedItems, setSelectedItems] = useState([]);
  const [shuffledData, setShuffledData] = useState([]);
  const [rate, setRate] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const allNfts = await fetchNftData(uid, '', '0', '10000');

      switch (title.toLowerCase()) {
        case 'arts':
          setSelectedItems(allNfts);
          break;
        case 'market':
          setSelectedItems(allNfts.filter(item => item.status === 'market'));
          break;
        case 'sold':
          setSelectedItems(allNfts.filter(item => item.status === 'sold'));
          break;
        default:
          console.error('Invalid title:', title);
          break;
      }
    } catch (error) {
      console.error(error);
    }

    try {
      const ethToUsdRate = await fetchEthToUsdRate();
      setRate(ethToUsdRate);
      console.log("ETH to USD Rate", { ethToUsdRate });
    } catch (error) {
      console.error("Failed to fetch ETH to USD rate", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setShuffledData(selectedItems);
  }, [selectedItems]);

  return (
    <Container className="pt-16">
      <div className="flex items-center justify-between">
        <span className="font-poppins text-lg font-semibold text-slate-900 md:text-2xl">{title}</span>
        
      </div>
      <div className="-mx-4 sm:mx-0">
        {shuffledData.length > 0 ?
        <Swiper
          navigation
          //pagination={{ clickable: true }}
          className="collections-slide"
          breakpoints={{
            0: {
              slidesPerView: 'auto',
              slidesPerGroup: 1,
              centeredSlides: true,
              centeredSlidesBounds: true,
              spaceBetween: 8,
              slidesOffsetBefore: 16,
              slidesOffsetAfter: 16,
            },
            600: { slidesPerView: 2, slidesPerGroup: 2, ...commonBiggerScreen },
            768: { slidesPerView: 3, slidesPerGroup: 3, ...commonBiggerScreen },
            1024: { slidesPerView: 4, slidesPerGroup: 4, ...commonBiggerScreen },
            1200: { slidesPerView: 5, slidesPerGroup: 5, ...commonBiggerScreen },
            1600: { slidesPerView: 6, slidesPerGroup: 6, ...commonBiggerScreen },
          }}
        >
          {shuffledData
          .filter(item => item.minted === "true")
          .map((item, i) => (
            <SwiperSlide key={i}>
              <Link to={`/product/${item.link}`}>
                <div className="cursor-pointer overflow-hidden rounded-2xl shadow duration-200 will-change-transform hover:-translate-y-1 hover:shadow-md sm:w-full">
                  <div className="relative aspect-video">
                    <LazyLoad height={200} offset={100} placeholder={<p>Loading...</p>}>
                      <img alt={item.title} src={`https://nftnexus.art/snooart/nftuploads/${item.link}`} className="absolute inset-0 h-full w-full object-cover object-top" />
                    </LazyLoad>
                  </div>
                  <div className="p-4">
                    <p className="font-bold text-slate-900 single-line mb-2">{item.title}</p>
                    <p className="flex items-center gap-1 text-sm font-semibold text-mid-300 d-none"><Link to={`profile/${item.uniqueid}`}>{item.owner_username}</Link></p>
                    <div className="mt-4 flex gap-x-8">
                      <div>
                        <p className="text-sm">Floor</p>
                        <p className="font-semibold text-slate-900" style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                          <span className="text-green-500 flex items-center gap-1.5 text-sm md:text-base" style={{ display: 'flex', alignItems: 'center', fontWeight: 5000 }}>
                            <img alt="weth" loading="lazy" decoding="async" data-nimg="fill" sizes="100vw"
                              src='/img/weth.webp'
                              style={{
                                height: '20px',  // Example height, adjust as needed
                                width: '20px',   // Example width, adjust as needed
                                marginRight: '10px'  // Adds space between the image and the text
                              }} />
                            <span style={{ fontWeight: '5000 !important' }}> {item.price} WETH </span>
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="text-sm">USD</p>
                        <p className="font-semibold text-slate-900">
                          <span className="text-xs ml-2">{
                            (item.price * rate).toLocaleString(undefined, {
                              style: 'currency',
                              currency: 'USD',
                              currencyDisplay: 'symbol'
                            }).replace('US$', '$')}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
         : <div className="flex items-center justify-between">No {title} Item Yet</div>}
      </div>
    </Container>
  );
}

export default ProfileCollection;
