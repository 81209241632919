import React from "react";
import { Link } from "react-router-dom";

const Salessingle = (props) => {
    return(
        <div className="d-flex justify-content-between" style={{ margin: "10px", width: "100%" }}>
        <div className="d-flex align-items-center gap-3">
            <div className="p-2 rounded-circle font-bold bg-success text-white">
                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="14" width="14" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8"></path>
                    <path d="M3 3v5h5"></path>
                    <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16"></path>
                    <path d="M16 16h5v5"></path>
                </svg>
            </div>
            <div className="d-flex flex-column gap-1">
                <span className="text-sm font-weight-bold line-clamp-1">{props.title}</span>
                <span className="text-sm text-muted -mt-0.5">{props.date}</span>
            </div>
        </div>
    
        <div className="text-right">
            <div className="text-sm font-weight-bold">{props.price} ETH</div>
            <span className="text-sm text-muted"> {props.myid === props.sellerid ? 'sold to ' + props.buyername : 'from ' + props.sellername}</span>
        </div>
    </div>
    
    );
}
export default Salessingle; 