import React, { useState, useEffect } from "react";
import BaseLayout from "../components/layouts/BaseLayout";
import Container from "../components/Container";
import { useParams } from "react-router-dom";
import { useAuth } from "../../dashboard/context/AuthContext";
import ProfileCollection from "../components/ProfileCollection";

const Profile = () => {
  const [profileData, setProfileData] = useState([]);
  const { username } = useParams();
  const auth = useAuth();

  const fetchProfile = async (username) => {
    try {
      const resp = await auth.fetchProfile(username);
      setProfileData(resp);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchProfile(username);
  }, [username]);

  return (
    <BaseLayout>
      <div className="relative bg-white">
        <div className="absolute inset-0 z-0 bg-cover bg-top bg-no-repeat transition-[background] duration-500 after:absolute after:inset-0 after:z-10 after:backdrop-blur-xl after:[background:linear-gradient(0deg,rgb(255,255,255)_5%,rgba(0,0,0,0)_60%)_rgba(0,0,0,0.5)]" style={{ background:'white' }} />
        <div className="relative z-10 -mt-[4.25rem] pt-[4.25rem] bg-white">
          <Container className="px-0 sm:px-4 bg-white">
            {profileData.length > 0 ? (
              profileData.map((item, i) => (
                <div className="white_box mb_30" style={{ marginTop:'-100px', margin:'0px', padding:'0px' }}>  
                  <div className="profile_card_5" key={i}>
                    <div className="cover-photo" style={{ marginTop: '-150px', height:'320px', backgroundImage: `url(${item.profile_photo  ? `${auth.defUrllink}useruploads/${item.profile_photo}` : "../img/bg.jpg"})` }}>
                      <img src={item.profile_photo !== ''  ? `${auth.defUrllink}useruploads/${item.profile_photo}` : "../img/client_img.png"} className="profile" style={{ position: 'absolute', top: '105px', background: 'white', border: '0px solid #1f1a32',padding: '3px' }} />
                    </div>
                    <div style={{ margin: '0px', padding: '30px', marginTop: '1px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <h1 className="text-3xl md:text-4xl font-[900] line-clamp-1 leading-relaxed md:leading-loose ibm-plex-sans" style={{ margin: '0px', marginTop: '15px' }}>
                                {item.username}
                            </h1>
                            <svg  stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="text-primary text-[#2081e2] text-base md:text-lg" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: '8px' }}>
                                <path fill="none" d="M0 0h24v24H0z"></path>
                                <path d="M23 12l-2.44-2.79.34-3.69-3.61-.82-1.89-3.2L12 2.96 8.6 1.5 6.71 4.69 3.1 5.5l.34 3.7L1 12l2.44 2.79-.34 3.7 3.61.82L8.6 22.5l3.4-1.47 3.4 1.46 1.89-3.19 3.61-.82-.34-3.69L23 12zm-12.91 4.72l-3.8-3.81 1.48-1.48 2.32 2.33 5.85-5.87 1.48 1.48-7.33 7.35z"></path>
                            </svg>
                        </div>
                        <p className="about ibm-plex-sans">{item.bio ? item.bio : 'No biography yet..........'}</p>
                    </div>


                  </div>
                  <ProfileCollection uid={username} title={'Arts'} />
                  {/* <ProfileCollection uid={username} title={'Market'} /> */}
                  {/* <ProfileCollection uid={username} title={'Sold'} /> */}
                </div>
              ))
            ) : (
              <div>No profile found</div>
            )}
          </Container>
          
        </div>
      </div>
    </BaseLayout>
  );
};

export default Profile;
