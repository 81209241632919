import React from 'react';
import { useAuth } from '../context/AuthContext';
import Copy from '../api/copy';
import { fetchEthToUsdRate } from '../api/ethrate';
import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';


const NftSingle = (props) => {
  const [isDataFetched, setIsDataFetched] = useState(false);
  const { defUrllink,pushmarket } = useAuth();
  const image = defUrllink + 'nftuploads/' + props.image;
  console.log(props);
  const [rate, setRate] = useState(null);
  const [checked, setChecked] = useState(props.minted !== "true" ? false: true);

  const handlepush = async (event, id) =>{
    try {
      const isChecked = event.target.checked

      const response = await pushmarket(id,isChecked);
      console.log(response.status);
      setChecked(response.message !== "true" ? false: true);
  } catch (error) {
      console.error('Fetching data failed:', error);
      // Handle error
  }
  }

  const fetchData = useCallback(async () => {
    try {
      const ethToUsdRate = await fetchEthToUsdRate();
      setRate(ethToUsdRate);
      console.log("ETH to USD Rate", { ethToUsdRate });
    } catch (error) {
      console.error("Failed to fetch ETH to USD rate", error);
    }

    setIsDataFetched(true);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, fetchEthToUsdRate,checked]);

  return (
    <div className="col-lg-4 col-6 p-1" style={{}}>

      <div className='relative w-full shadow-lg shadow-dark-100/10 rounded-lg md:rounded-xl'>
        <div className="relative w-full aspect-[5/4]">

          <div className="absolute inset-0 bg-gradient-to-tr from-black/0 via-black/10 to-black/60 rounded-t-lg md:rounded-t-xl"></div>
          <Link to={`/product/${props.image}`}>
            <img
              alt="Listening"
              loading="lazy"
              decoding="async"
              data-nimg="fill"
              className="object-cover rounded-t-lg md:rounded-t-xl"
              sizes="100vw"
              src={image}
              style={{ position: 'absolute', height: '100%', width: '100%', inset: '0px', color: 'transparent' }}
            />
          </Link>
        </div>
        <div className="bg-white rounded-b-xl p-3 md:p-4">
          <Link to={`/product/${props.image}`}>
            <p className="text-base font-bold capitalize mb-1 line-clamp-1 md:mb-2">{props.name}</p>
          </Link>
          <div className="flex items-center justify-between mt-1 text-sm md:text-base">


            <div className="flex items-center gap-2">
              <p class="flex items-center gap-1.5 mt-2 text-sm md:text-base">
                <div class="relative aspect-square w-5">
                  <img alt="weth" loading="lazy" decoding="async" data-nimg="fill" sizes="100vw"
                    src='https://nftnexus.art/img/weth.webp'
                    style={{
                      position: 'absolute',
                      height: '100%',
                      width: '100%',
                      inset: 0,
                      color: 'transparent'
                    }} />

                </div>
                <span class="font-bold" style={{ whiteSpace: 'nowrap' }}>{props.price} WETH</span>
                <br />

                {props.mine ? (
                  <button
                    type="button"
                    id="radix-:rc:"
                    aria-haspopup="menu"
                    aria-expanded="false"
                    data-state="closed"
                    className="peer inline-flex h-5 w-[39px] shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=unchecked]:bg-neutral-200 dark:focus-visible:ring-neutral-300 dark:focus-visible:ring-offset-neutral-950 dark:data-[state=checked]:bg-neutral-50 dark:data-[state=unchecked]:bg-neutral-800 data-[state=checked]:bg-brand"
                  >
                    <div className="text-brand cursor-pointer">
                      <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="text-lg mb-1 md:text-xl"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                        <path d="M18.5 2.5a2.12 2.12 0 0 1 3 3L12 15l-4 1 1-4Z"></path>
                      </svg>
                    </div>
                  </button>
                ) : null}
              </p>
            </div>
          </div>
          <p>
            <span class="text-xs ml-2">{
              (props.price * rate).toLocaleString(undefined, {style: 'currency',currency: 'USD',currencyDisplay: 'symbol'}).replace('US$', '$')}
            </span>
          </p>
          {props.mine ? (
            <div className="flex items-center justify-between mt-1 text-sm md:text-base">
              <span className="text-mid-200 mr-1" style={{ whiteSpace: 'nowrap' }}>Push to market </span>
              <div className="flex items-center gap-2 m-1">
                <div className="form-check form-switch d-inline-block mt-1" style={{ float: 'right' }}>
                  <input className="form-check-input" checked={checked} onChange={(event) => handlepush(event,props.image)} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className={props.minted !== 'true' ? 'd-none': 'absolute top-3 left-3'}>
          <button className="inline-flex items-center justify-center rounded-lg font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-25 dark:ring-offset-neutral-950 dark:focus-visible:ring-neutral-300 dark:bg-neutral-50 dark:text-neutral-900 dark:hover:bg-neutral-50/90 h-8 w-8 border-none text-white bg-gray-900/25 backdrop-blur hover:bg-gray-900/40">
            <Copy text={'https://nftnexus.art/product/' + props.image} />
          </button>
        </div>
        {props.mine ? (
          <div className="absolute top-3 right-3">
            <button
              type="button"
              role="combobox"
              aria-controls="radix-:re:"
              aria-expanded="false"
              aria-autocomplete="none"
              dir="ltr"
              data-state="closed"
              className="flex items-center justify-between border border-neutral-200 px-1 py-2 placeholder:text-neutral-500 focus:outline-none focus:ring-0 focus:border-neutral-400 focus:ring-neutral-950 focus:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50 dark:border-neutral-800 dark:bg-neutral-950 dark:ring-offset-neutral-950 dark:placeholder:text-neutral-400 dark:focus:ring-neutral-300 w-fit gap-1 h-7 border-none text-xs text-white bg-gray-900/25 backdrop-blur rounded-full md:text-sm"
            >
              <span style={{ pointerEvents: 'none' }}>{props.category}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-4 w-4 opacity-50"
                aria-hidden="true"
              >
                <path d="m6 9 6 6 6-6"></path>
              </svg>
            </button>
          </div>
        ) : null}

      </div>

    </div>
  )
}

export default NftSingle;