import React, { useState, useEffect } from 'react';
import { Autoplay, Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Container from './Container';
import { MAIN_TABS } from '../consts';
import { useAuth } from '../../dashboard/context/AuthContext';
import { Link } from 'react-router-dom';

export default function HeroSection() {
  const [background, setBackground] = useState(0);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const { allNfts } = useAuth();

  useEffect(() => {
    if (allNfts.length > 0) {
      setIsDataFetched(true);
    }
  }, [allNfts]);

  return (
    <div className="relative">
      {allNfts.length > 0 ? (
        <div className="absolute inset-0 z-0 bg-cover bg-top bg-no-repeat transition-[background] duration-500 after:absolute after:inset-0 after:z-10 after:backdrop-blur-xl after:[background:linear-gradient(0deg,rgb(255,255,255)_5%,rgba(0,0,0,0)_60%)_rgba(0,0,0,0.5)]" style={{ backgroundImage: `url(https://nftnexus.art/snooart/nftuploads/${allNfts[background].link})` }} />
      ) : null}
      <div className="relative z-10 -mt-[4.25rem] pt-[4.25rem]">
        <Container>
          <Tabs />
        </Container>
        <Container className="px-0 sm:px-4">
          <Carousel changeBackground={setBackground} allNfts={allNfts} />
        </Container>
      </div>
    </div>
  );
}

function Carousel({ changeBackground, allNfts }) {
  const [loaded, setLoaded] = useState(false);

  const handleLoad = () => {
    setTimeout(() => {
      setLoaded(true);
    }, 3000); // Wait for 3 seconds before setting loaded to true
  };

  return (
    <div className="pt-8">
      {allNfts.length > 0 ? (
        <Swiper
          id="carousel"
          modules={[Navigation, Autoplay, Mousewheel]}
         
         
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          mousewheel={{ invert: false, forceToAxis: true }}
          onSlideChange={(e) => changeBackground(e.realIndex)}
          navigation
          breakpoints={{
            0: { slidesPerView: 'auto', slidesPerGroup: 1, centeredSlides: true, spaceBetween: 8 },
            600: { slidesPerView: 2, slidesPerGroup: 2, centeredSlides: false, spaceBetween: 15 },
            768: { slidesPerView: 3, slidesPerGroup: 3, centeredSlides: false, spaceBetween: 15 },
            1024: { slidesPerView: 4, slidesPerGroup: 4, centeredSlides: false, spaceBetween: 15 },
            2256: { slidesPerView: 6, slidesPerGroup: 6, centeredSlides: false, spaceBetween: 15 },
          }}
        >
          {allNfts
          // .filter(item => item.minted === 'true')
          .map((item, i) => (
            <SwiperSlide key={i}>
              <Link to={`product/${item.link}`}>
              <div className="group relative flex aspect-square w-full cursor-pointer overflow-hidden rounded-2xl bg-white/10" key={i}>
               {!loaded && <div className="animated-background" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></div>}
                <img src={`https://nftnexus.art/snooart/nftuploads/${item.link}`} loading="lazy" className="pointer-events-none absolute h-full w-full origin-center scale-[1.01] select-none object-cover object-center transition-transform duration-500 will-change-transform group-hover:scale-110"
                  style={{ 
                    // width: '100%', 
                    // height: '100%', 
                    display: loaded ? 'block' : 'none'
                  }}
                  onLoad={handleLoad}
                   alt={item.title} />
                <div className="pointer-events-none relative mt-auto flex h-1/2 w-full select-none bg-gradient-to-t from-black/80 p-4 text-white">
                  <div className="relative mt-auto">
                    <h4 className="font-semibold bold text-white">{item.title}</h4>
                    <span className="block text-sm bold">Floor: {item.price} WETH</span>
                  </div>
                </div>
              </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

function Tabs() {
  return (
    <div className="pt-8 sm:px-0">
      <nav className="scroll-mask" id="main-tabs">
        <Swiper spaceBetween={16} slidesPerView={'auto'} freeMode={true} navigation modules={[Navigation]}>
          {MAIN_TABS.map((tab) => (
            <SwiperSlide key={tab} className="rounded-lg first:bg-white/10 hover:bg-white/10">
              <Link to={tab === 'All' ? '' : tab} className="block w-fit px-4 py-2 text-sm font-semibold text-white md:text-base">
                {tab}
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </nav>
    </div>
  );
}
