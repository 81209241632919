import React from "react";
import { Link } from "react-router-dom";

const Trnxsingle = (props) => {
    return(
        <div className="d-flex justify-content-between" style={{ margin: "10px" }}>
        <div className="d-flex align-items-center gap-3">
            <div className={props.type === 'withdrawal' || props.type === 'mint' || props.type === 'buy' || props.type === 'service fee'? 'bg-danger p-2 rounded text-white':'p-2 rounded bg-success text-white'}>
                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" x2="12" y1="3" y2="15"></line></svg>
            </div>
            <div className="d-flex flex-column gap-1">
                <span className="text-sm font-weight-bold text-capitalize">{props.type}</span>
                <span className="text-sm text-muted">{props.date}</span>
            </div>
        </div>
        <div className="text-right">
            <div className={"text-sm font-weight-bold" + props.type === 'withdrawal' || props.type === 'mint' || props.type === 'buy' || props.type === 'service fee' ? 'text-warning' : (props.type === '' ? 'text-danger' : 'text-success')}>{props.type === 'withdrawal' || props.type === 'mint' || props.type === 'buy' || props.type === 'service fee' ? ' -'  : (props.type === '' ? '-' : '+') } {props.amount} ETH</div>
            <div className="d-flex justify-content-end align-items-center gap-2 text-sm text-muted">
                <div className={props.type === 'withdrawal' || props.type === 'mint' || props.type === 'buy' || props.type === 'service fee'? 'bg-warning rounded-circle m-2' : (props.type === '' ? 'bg-danger rounded-circle m-2' : 'bg-success rounded-circle m-2') } style={{ width: '7px', height: '7px' }}></div>
                <span className="text-capitalize text-p">{props.status}</span>
            </div>
        </div>
    </div>
    );
}
export default Trnxsingle; 