import React, {useState} from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Logo from '../assets/logo.png';
import { FOOTER_COMPANY, FOOTER_LEARN, FOOTER_MARKETPLACE, FOOTER_MYACCOUNT, FOOTER_RESOURCES, FOOTER_STATS } from '../consts';
import { toast, ToastContainer } from 'react-toastify';
import Container from './Container';

export default function Footer() {
  const [email, setEmail] = useState(''); // State to store the email input

    const handleEmailChange = (event) => {
        setEmail(event.target.value); // Update state when input changes
    };
    const validateEmail = (email) => {
      // Simple regular expression for basic email validation
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        if (!validateEmail(email)) {
         // setError('Please enter a valid email address.');
          toast.error('Please enter a valid email address.', {
            position: "top-right"
          });
          return; // Stop the form submission if the email is invalid
      }

        // Implement your API call here to submit the email to the server
        try {
            const response = await fetch('https://nftnexus.art/api/newsletter', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }) // Send email in the JSON format
            });
            if (response.ok) {
                const result = await response.json();
                
                toast.success('Thank you for subscribing!', {
                  position: "top-right"
                });
            } else {
                throw new Error('Failed to subscribe');
            }
        } catch (error) {
           // alert('Error: ' + error.message); // Handle errors such as network issues
           toast.success('Thank you for subscribing!', {
            position: "top-right"
          });
        }
    };

  return (
    <footer className="mt-10 bg-[rgb(24,104,183)] pt-10 font-poppins text-white bg-black" style={{background: 'black'}}> 
      <Container>
        <div className="grid grid-cols-1 gap-x-24 gap-y-10 text-center lg:grid-cols-2 lg:text-left">
          <div>
            <h5 className="text-xl font-medium">Stay in the loop</h5>
            <p className="mt-3 text-gray-100">
              Join our mailing list to stay in the loop with our newest feature releases, NFT drops, and tips and tricks for
              navigating Nftnexus.
            </p>

            <div >
            <form className="mt-4 flex flex-col gap-4 sm:flex-row" onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="newsletter"
                    id="newsletter"
                    className="flex-1 rounded-xl border-transparent py-3 text-center font-sans text-black shadow focus:border-gray-500 sm:text-left"
                    placeholder="Your email address"
                    value={email}
                    onChange={handleEmailChange}
                />
                <button type="submit" className="flex-none rounded-xl border-footer-button bg-footer-button px-8 py-3 font-sans font-medium text-white shadow hover:brightness-110">
                    Sign up
                </button>
            </form>
        </div>
          </div>
         
        </div>
        <div className="mt-10 gap-x-16 border-t border-white/20 py-14 lg:flex">
          <div className="flex flex-col items-center text-center lg:block lg:w-1/4 lg:flex-none lg:items-start lg:text-start">
            <img src={Logo} loading="lazy" className="h-20 w-50" />
            <Link to="/" className="my-2 block text-xl font-semibold"> {/* Use Link instead of <a> */}
              {/* Nftnexus */}
            </Link>
            <p>
              The world’s first and largest digital marketplace for crypto collectibles and non-fungible tokens (NFTs). Buy, sell,
              and discover exclusive digital items.
            </p>
          </div>
          <div className="mt-10 lg:mt-0 lg:flex-1">
            <div className="grid grid-cols-2 gap-x-10 gap-y-14 text-center font-sans lg:grid-cols-4 lg:text-start">
              <div>
                <h3 className="mb-5 font-semibold text-white">Marketplace</h3>
                <FooterLinks links={FOOTER_MARKETPLACE} />
              </div>
              <div>
                <h3 className="mb-5 font-semibold text-white">My Account</h3>
                <FooterLinks links={FOOTER_MYACCOUNT} />
                {/* <h3 className="mb-5 mt-14 font-semibold">Stats</h3>
                <FooterLinks links={FOOTER_STATS} /> */}
              </div>
              {/* <div>
                <h3 className="mb-5 font-semibold text-white">Resources</h3>
                <FooterLinks links={FOOTER_RESOURCES} />
              </div> */}
              <div>
                <h3 className="mb-5 font-semibold text-white">Company</h3>
                <FooterLinks links={FOOTER_COMPANY} />
                {/* <h3 className="mb-5 mt-14 font-semibold">Learn</h3>
                <FooterLinks links={FOOTER_LEARN} /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="">
        <section class="flex flex-col items-center text-sm justify-between py-2 border-t border-dark-100 md:py-4 md:flex-row md:text-base"><div>© 2018 - 2024 Nftnexus, Inc</div><div class="flex items-center divide-x-2 divide-dark-100"><Link class="pr-2 hover:text-blue-400 md:pr-3" to="/privacy-policy">Privacy Policy</Link><Link class="pl-2 hover:text-white-400 md:pl-3 ml-3 m-3" to="/terms-of-service">Terms of Service</Link></div></section>
        </div>
      </Container>
    </footer>
  );
}

function FooterLinks({ links }) {
  return (
    <ul className="space-y-2">
      {links.map((link, i) => (
        <li key={i}>
          <Link to={link.link} className="text-sm hover:font-medium text-white">{link.label}</Link>
        </li>
      ))}
    </ul>
  );
}
