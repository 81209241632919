import React from "react";
import { useState,useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import Copy from "../api/copy";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router";
import DisplaySales from "./displaysales";
import { Link } from "react-router-dom";
import DisplayTrnx from "./displaytrnx";



const Deposit = () => {
    const [userdata, setUserData] = useState([]);
    const { userData,fetchUserData } = useAuth();
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1); // Navigate back one step
    };

    async function fetchData() {
        // fetch data    
        const resp = await fetchUserData();
        setUserData(resp);

    }
  

    useEffect(() => {
        fetchData();
      
      }, []);
    return (
        <div class="main_content_iner ">
            <div class="container-fluid plr_30 body_white_bg pt_10">
                <div class="row justify-content-center">
                <div class="text-2xl md:text-3xl">
                <button onClick={goBack} class="mb-2 justify-center rounded-lg font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-25 dark:ring-offset-neutral-950 dark:focus-visible:ring-neutral-300 dark:bg-neutral-50 dark:text-neutral-900 dark:hover:bg-neutral-50/90 py-2 flex items-center gap-1 text-sm text-charcoal h-9 px-2 bg-light-100 hover:bg-light-200"><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m15 18-6-6 6-6"></path></svg><div class="mr-1">Back</div></button>

                   </div>

                    <div class="row justify-content-center">
                        <div className="col-lg-12 col-xl-8">

                            <div class="single_element">
                                <div class="quick_activity">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="">
                                                <div class="" style={{ backgroundColor: 'rgb(245, 245, 245)' }}>
                                                    <div class="d-flex justify-content-between gap-3 px-6 py-4  rounded-xl md:px-8 md:py-6">
                                                        <div>Just made a deposit?</div>
                                                        <button class="btn btn-primary" >
                                                            <span>Refresh Now</span>
                                                        </button>
                                                    </div>

                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="single_element mt-2">
                                <div class="quick_activity">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="">
                                                <div class="m-2" style={{ backgroundColor: 'rgb(245, 245, 245)' }}>
                                                    <section class="flex flex-col justify-between gap-8 px-6 py-8 bg-light-100 rounded-xl md:px-8 md:py-12">
                                                        <div class="d-flex flex-column align-items-center gap-3 md:align-items-start md:gap-4">
                                                            <div class="position-relative aspect-square w-48 md:w-56">
                                                                <img
                                                                    alt="qrcode"
                                                                    loading="lazy"
                                                                    decoding="async"
                                                                    data-nimg="fill"
                                                                    className="w-48 md:w-56 img-fluid w-100"
                                                                    sizes="100vw"
                                                                    src={"https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=ethereum:"+'0x'+userdata.map(user => user.eth_address)}                                                                    style={{
                                                                        // position: 'absolute',
                                                                        height: '100%',
                                                                        width: '100%',
                                                                        inset: 0,
                                                                        color: 'transparent'
                                                                    }}
                                                                />

                                                            </div>

                                                            <p class="text-sm md:text-base text-dark">Scan QR Code to deposit</p>
                                                        </div>

                                                        <div class="d-flex flex-column gap-3">
                                                            <div>
                                                                <span class="text-secondary">Wallet Address</span>
                                                                <div class="d-flex gap-2 md:gap-4">
                                                                {userdata.map(user => (
                                                                    <strong className="font-weight-bold text-bold bold d-xs-block text-dark bold" >
                                                                        {user.eth_address.length > 20 ? '0x'+user.eth_address.substring(0, 20) + '...' : '0x'+user.eth_address}
                                                                    </strong>
                                                                ))}
                                                                    
                                                                    <div class="cursor-pointer">
                                                                        <Copy  text={'0x'+userdata.map(user => user.eth_address)} />
                                                                        </div>
                                                                        </div>
                                                                        </div>
                                                            <div>
                                                                <span class="text-secondary text-dark">Network</span>
                                                                <p class="font-weight-bold text-dark">Ethereum (ERC20)</p></div>
                                                        </div>

                                                    </section>

                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="single_element mt-2">
                                <div class="quick_activity">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="">
                                                <div class="single_quick_activity" style={{ backgroundColor: 'rgb(245, 245, 245)' }}>
                                                    <section class="d-flex flex-column justify-content-between gap-3 px-3 py-4  rounded">
                                                        <div class="d-flex flex-column gap-3">
                                                            <div>
                                                                <span class="text-warning">Expected arrival</span>
                                                                <p class="text-dark">12 network confirmations</p>
                                                            </div>
                                                            <div>
                                                                <span class="text-warning">Minimum deposit</span>
                                                                <p className="text-dark">0.00000001 ETH</p>
                                                            </div>
                                                            <div>
                                                                <span class="text-warning">Expected unlock</span>
                                                                <p className="text-dark">12 network confirmations</p>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex flex-column gap-1">
                                                            <h4 class="text-2xl fw-bold w-400 text-dark">Note</h4>
                                                            <ul class="list-unstyled">
                                                                <li>Send only ETH to this deposit address.</li>
                                                                <li>Ensure the network is Ethereum (ERC20).</li>
                                                            </ul>
                                                        </div>
                                                    </section>

                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-xl-4 mt-sm-1 mt-2 mt-lg-0 quick_activity" >
                            <div class='p-1 single_quick_activity' style={{ backgroundColor: 'rgb(245, 245, 245)', borderRadius: '10px' }}>



                                <div class=" d-flex justify-content-between align-items-end ">
                                    <h6 className='m-3' style={{ color: 'black', fontWeight: 300 }}>Recent Transactions</h6>
                                    <Link to="/dashboard/transaction-history" type="button" className="btn btn-secondary btn-sm m-3" style={{ backgroundColor: '#f5f5f5', color: 'black' }}>View all</Link>
                                </div>
                                <div className="flex flex-col gap-4">
                                <DisplayTrnx limit={10} page={'false'} type={'All'}/>

                                    


                                </div>

                            </div>

                            <div class='p-1 mt-3 single_quick_activity' style={{ backgroundColor: 'rgb(245, 245, 245)', borderRadius: '10px' }}>


                                <div class=" d-flex justify-content-between align-items-end ">
                                    <h6 className='m-3' style={{ color: 'black', fontWeight: 300 }}>Recent Transactions</h6>
                                    <Link to="/dashboard/transaction-history" type="button" className="btn btn-secondary btn-sm m-3" style={{ backgroundColor: '#f5f5f5', color: 'black' }}>View all</Link>
                                </div>
                                <div className="flex flex-col gap-4">
                                    
                                    <DisplaySales limit={10} page={'false'} />




                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default Deposit;