import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../dashboard/context/AuthContext";
import Salesingle from "../components/salesSingle";
import { useState, useEffect } from "react";



const DisplaySales = (props) => {
    const [data, setData] = useState([]);
    const { fetchSales, UserData } = useAuth();

    const [pagination, setPagination] = useState({
        offset: 0,
        limit: props.limit,
        page: 1,
    });
    const Userdata = {
        userid: UserData ? UserData.map(user => user.uniqueid) : '',
        category: '',
        offset: pagination.offset,
        limit: pagination.limit
    };

    const prevPage = () => {
        setPagination(prev => ({
            ...prev,
            offset: Math.max(0, prev.offset - prev.limit),
            page: prev.page - 1
        }));
        fetchData();
    }

    const nextPage = () => {
        setPagination(prev => ({
            ...prev,
            offset: prev.offset + prev.limit,
            page: prev.page + 1
        }));
        fetchData();
    }

    const fetchData = async () => {


        try {
            const response = await fetchSales( Userdata.offset, props.limit);
            setData(response); // Store user data in state
            // Redirect user to dashboard or any other page upon successful registration
        } catch (error) {
            console.error('Registration failed:', error);
            //setError('Registration failed. Please try again.');
        }
    }


    useEffect(() => {
        fetchData();
    }, [pagination]);
  return (
    <> {data.length > 0 ?
        <div className='row p-0 m-1'>
        {data.map((nft) => (
            <Salesingle
            title={nft.title}
            date={nft.date}
            price={nft.price}
            buyername={nft.buyername}
            sellername={nft.sellername}
            sellerid={nft.sellerid}
            myid={Userdata.userid}
            />
        ))}
        </div>
        : 
        <div class="flex flex-col gap-3 items-center justify-center my-4 md:my-6">
             <p class="italic">No sales yet.</p>
             <Link class="mt-3" to="/dashboard/marketplace">
                 <button class="justify-center font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-25 dark:ring-offset-neutral-950 dark:focus-visible:ring-neutral-300 text-neutral-50  dark:text-neutral-900 dark:hover:bg-neutral-50/90 h-11 px-6 py-2 flex items-center gap-2 rounded-md w-40 bg-brand hover:bg-brand/80 md:w-64 lg:w-72 bg-secondary">Make a sale
                 </button>
             </Link>
         </div>
        }
        <>
       { props.page === 'true' ? <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
                <li className="page-item ">
                    <button disabled={pagination.page === 1} className="page-link" onClick={prevPage}>Previous</button>
                </li>
                <li className="page-item"><a className="page-link" >{pagination.page}</a></li>
                <li className="page-item">
                    <button disabled={data.length < pagination.limit} className="page-link" onClick={nextPage}>Next</button>
                </li>
            </ul>

        </nav> : 
        <></>
        }

            </>
    </>
  );
};

export default DisplaySales;